<template>
  <div class="totalBody">
    <div class="headerBox">
      <p>捷思联想检验智能管控系统-看板</p>
      <div class="lineName">{{ line.name }}</div>
      <div class="currentDate">{{ nowTime }}</div>
    </div>

    <el-row class="rows" :gutter="30">
      <el-col :span="12">
        <el-row>
          <div class="reportBox reportBox1">
            <h4 class="chartTitle">产线投产状况</h4>
            <div class="tableBox">
              <el-table :data="report1Data">
                <el-table-column align="center" prop="typeName" label="类别"></el-table-column>
                <el-table-column align="center" prop="totalCnt" label="线体"></el-table-column>
                <el-table-column align="center" prop="appearance" label="外观"></el-table-column>
                <el-table-column align="center" prop="electric" label="电性能"></el-table-column>
                <el-table-column align="center" prop="goldenKey" label="金钥匙"></el-table-column>
              </el-table>
            </div>
          </div>
        </el-row>
        <el-row style="padding-top: 30px">
          <div class="reportBox" style="height: 160px">
            <h4 class="chartTitle">产线检验状况</h4>
            <div class="tableBox">
              <el-table :data="report2Data">
                <el-table-column align="center" prop="typeName" label="类别"></el-table-column>
                <el-table-column align="center" prop="totalCnt" label="线体"></el-table-column>
                <el-table-column align="center" prop="appearance" label="外观"></el-table-column>
                <el-table-column align="center" prop="electric" label="电性能"></el-table-column>
                <el-table-column align="center" prop="goldenKey" label="金钥匙"></el-table-column>
              </el-table>
            </div>
          </div>
        </el-row>
        <el-row style="padding-top: 30px">
          <div class="reportBox" style="height: 115px">
            <h4 class="chartTitle">产线包装状况</h4>
            <div class="tableBox">
              <el-table :data="report3Data">
                <el-table-column align="center" prop="packCapacityCnt" label="配置产能"></el-table-column>
                <el-table-column align="center" prop="packCnt" label="已包装"></el-table-column>
                <el-table-column align="center" prop="cnt" label="剩余产能需求"></el-table-column>
                <el-table-column align="center" prop="waitPackCnt" label="待包装"></el-table-column>
              </el-table>
            </div>
          </div>
        </el-row>
      </el-col>
      <el-col :span="12">
        <div class="reportBox reportBox2">
          <h4 class="chartTitle" style="position: relative">加急完成状况
            <div style="position:absolute;top: 7px;right: 25px;font-size: 16px;text-align: left">合计：已完成
              <div style="width: 40px;display: inline-block">{{ dataV.finishTotal }}</div>
              剩余
              <div style="width: 50px;display: inline-block">{{ dataV.cnt }}</div>
            </div>
          </h4>
          <div class="tableBox">
            <dv-scroll-board
                :rowNum=24
                :config="dataV.config"
                style="width:100%;height:440px;"/>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row class="rows" :gutter="30">
      <el-col :span="7">
        <div class="reportBox reportBox3">
          <h4 class="chartTitle">在库时效状况</h4>
          <div class="tableBox">
            <el-table :data="inStoreAging">
              <el-table-column align="center" prop="typeName" label="类别"></el-table-column>
              <el-table-column align="center" prop="testCnt" label="待检验"></el-table-column>
              <el-table-column align="center" prop="packCnt" label="待包装"></el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
      <el-col :span="17">
        <el-row>
          <div class="reportBox" style="height: 160px">
            <h4 class="chartTitle">时效达成率</h4>
            <div class="tableBox">
              <el-table :data="agingFinishRate.data">
                <el-table-column align="center" prop="materialSource" label="类别"></el-table-column>
                <el-table-column align="center" prop="day0" :label="agingFinishRate.label[0]"></el-table-column>
                <el-table-column align="center" prop="day1" :label="agingFinishRate.label[1]"></el-table-column>
                <el-table-column align="center" prop="day2" :label="agingFinishRate.label[2]"></el-table-column>
                <el-table-column align="center" prop="day3" :label="agingFinishRate.label[3]"></el-table-column>
                <el-table-column align="center" prop="day4" :label="agingFinishRate.label[4]"></el-table-column>
                <el-table-column align="center" prop="day5" :label="agingFinishRate.label[5]"></el-table-column>
                <el-table-column align="center" prop="day6" :label="agingFinishRate.label[6]"></el-table-column>
              </el-table>
            </div>
          </div>
        </el-row>
        <el-row style="padding-top: 30px">
          <div class="reportBox" style="height: 160px">
            <h4 class="chartTitle">检验不良率</h4>
            <div class="tableBox">
              <el-table :data="ngRate.data">
                <el-table-column align="center" prop="materialSource" label="类别"></el-table-column>
                <el-table-column align="center" prop="day0" :label="ngRate.label[0]"></el-table-column>
                <el-table-column align="center" prop="day1" :label="ngRate.label[1]"></el-table-column>
                <el-table-column align="center" prop="day2" :label="ngRate.label[2]"></el-table-column>
                <el-table-column align="center" prop="day3" :label="ngRate.label[3]"></el-table-column>
                <el-table-column align="center" prop="day4" :label="ngRate.label[4]"></el-table-column>
                <el-table-column align="center" prop="day5" :label="ngRate.label[5]"></el-table-column>
                <el-table-column align="center" prop="day6" :label="ngRate.label[6]"></el-table-column>
              </el-table>
            </div>
          </div>
        </el-row>
      </el-col>
    </el-row>


  </div>
</template>

<script>
import {currentDate} from "@/utils/common";

export default {
  name: "LineReport",
  data() {
    return {
      line: {code: 'line1', name: '检测【1】线'},
      lineList: [
        {code: 'line1', name: '检测【1】线'},
        {code: 'line2', name: '检测【2】线'},
        {code: 'line3', name: '检测【3】线'},
        {code: 'line4', name: '检测【4】线'},
        {code: 'line5', name: '检测【5】线'},
      ],
      nowTime: currentDate(),
      report1Data: [],
      report2Data: [],
      report3Data: [],
      inStoreAging: [],
      agingFinishRate: {
        data: [],
        label: ['', '', '', '', '', '', ''],
      },
      ngRate: {
        data: [],
        label: ['', '', '', '', '', '', ''],
      },
      dataV: {
        config: {},
        header: ['借出单号', '备件编码', '需求日期', '已完成', '剩余'],
        align: ['center', 'center', 'center', 'center', 'center', 'center'],
        columnWidth: [60, 280, 150, 150, 120, 120],
        data: [],
        finishTotal: 0,
        cnt: 0
      },
    }
  },
  mounted() {
    document.title = '看板'
    let param = this.$route.params.lineCode;
    console.log(param);
    for (let i = 0; i < this.lineList.length; i++) {
      let lineObj = this.lineList[i];
      if (lineObj.code == param) {
        this.line = lineObj;
        break;
      }
    }


    setInterval(() => this.nowTime = currentDate(), 1000);
    this.callSortReport();
    this.callFinishReport();
    this.callPackReport();
    this.callInStoreAging();
    this.callAgingFinishRate();
    this.callNgRate();
    this.callUrgent();

    setInterval(() => {
      this.callSortReport();
      this.callFinishReport();
      this.callPackReport();
      this.callInStoreAging();
      this.callAgingFinishRate();
      this.callNgRate();
      this.callUrgent();
    }, 1000 * 600)
    this.dataV.config = {
      data: this.dataV.data,
      header: this.dataV.header,
      rowNum: 12,
      index: true,
      indexHeader: '序号',
      waitTime: 3000,
      headerBGC: 'transparent',
      oddRowBGC: 'transparent',
      evenRowBGC: 'rgba(30, 176, 252, .3)',
      align: this.dataV.align,
      columnWidth: this.dataV.columnWidth
    }

  },
  methods: {
    callUrgent() {
      let _this = this;
      this.$axios.get('report/queryAllNotFinish?lineCode=' + this.line.code).then(result => {
        let list = result.data.data || [];
        let data = [];
        let finishCnt = 0;
        let cnt = 0;
        for (let i = 0; i < list.length; i++) {
          let surplusCnt = list[i].amount - list[i].finishCnt;
          let tmp = [list[i].lendOrderCode, list[i].materialCode, list[i].ruleDate, list[i].finishCnt, surplusCnt];
          finishCnt += list[i].finishCnt;
          cnt += surplusCnt;
          data.push(tmp);
        }
        this.dataV.finishTotal = finishCnt;
        this.dataV.cnt = cnt;
        this.dataV.data = data;

        _this.dataV.config = {
          data: data,
          header: _this.dataV.header,
          rowNum: 12,
          index: true,
          indexHeader: '序号',
          waitTime: 3000,
          headerBGC: 'transparent',
          oddRowBGC: 'transparent',
          evenRowBGC: 'rgba(30, 176, 252, .3)',
          align: _this.dataV.align,
          columnWidth: _this.dataV.columnWidth
        }
      })
    },
    callSortReport() {
      this.$axios.get('report/queryCapacityReport?lineCode=' + this.line.code).then(result => {
        this.report1Data = result.data.data;
      })
    },
    callFinishReport() {
      this.$axios.get('report/queryLineFinishReport?lineCode=' + this.line.code).then(result => {
        this.report2Data = result.data.data;
      })
    },
    callPackReport() {
      this.$axios.get('report/queryLinePackReport?lineCode=' + this.line.code).then(result => {
        this.report3Data = result.data.data;
      })
    },
    callInStoreAging() {
      this.$axios.get('report/queryInStoreAging?lineCode=' + this.line.code).then(result => {
        this.inStoreAging = result.data.data;
      })
    },
    callAgingFinishRate() {
      this.$axios.get('report/queryAgingFinishRate?lineCode=' + this.line.code).then(result => {
        let arr = result.data.data;
        let row1 = {materialSource: '备件返还( 48H )'};
        let row2 = {materialSource: '其他( 72H )'};
        this.agingFinishRate.data = [row1, row2]
        for (let i = 0; i < arr.length; i++) {
          this.agingFinishRate.label[i] = arr[i].dayText;
          row1['day' + i] = arr[i].backRate
          row2['day' + i] = arr[i].otherRate
        }
      })
    },
    callNgRate() {
      this.$axios.get('report/queryBoardNgRate?lineCode=' + this.line.code).then(result => {
        let arr = result.data.data;
        let row1 = {materialSource: '外观'};
        let row2 = {materialSource: '电性能'};
        this.ngRate.data = [row1, row2]
        for (let i = 0; i < arr.length; i++) {
          this.ngRate.label[i] = arr[i].dayText;
          row1['day' + i] = arr[i].test1Rate
          row2['day' + i] = arr[i].test2Rate
        }
        console.log(this.ngRate)
      })
    },
  }
}
</script>

<style scoped>
.totalBody {
  color: #ffffff;
  min-width: 1900px;
  min-height: 1080px;
  background: url('../../assets/images/report/bg_report.png') no-repeat;
  background-size: cover; /* 拉伸图片覆盖整个区域 */
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.headerBox {
  width: 100%;
  height: 89px;
  background: url('../../assets/images/report/header.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
  margin-bottom: 20px;
  margin-top: 5px;
}

p {
  margin: 0 0 10px;
}

.headerBox > p {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.headerBox > div {
  position: absolute;
  top: 0;
}

.lineName {
  font-size: 18px;
  right: 200px;
  line-height: 35px;
}

.currentDate {
  font-size: 14px;
  right: 30px;
  line-height: 35px;
}

.rows {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 30px;
}

.chartTitle {
  font-weight: normal;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: center;
  color: #01c4f7;
}

.chartTitle:before {
  content: url('../../assets/images/report/title_before.png');
}

.chartTitle:after {
  content: url('../../assets/images/report/title_after.png');
}

.reportBox {
  background: url('../../assets/images/report/report1_bg.png') no-repeat;
  background-size: 100% 100%;
}

.reportBox1 {
  height: 207px;
}

.reportBox2 {
  height: 542px;
}

.reportBox3 {
  height: 350px;
}

.dv-scroll-board {
  color: #1eb0fc;
}

.dv-scroll-board /deep/ .header {
  font-size: 16px;
}

.tableBox /deep/ .el-table tr {
  background-color: transparent;
  border: none;
}

.tableBox /deep/ .el-table th, .el-table tr, .el-table td {
  background-color: transparent;
  border-style: none;
}

.tableBox /deep/ table tbody tr:nth-child(odd) {
  background: rgba(30, 176, 252, .3);
  border: none;
}

.tableBox /deep/ .el-table, .el-table__expanded-cell {
  background-color: transparent;
  border: none;
}

.tableBox /deep/ .el-table--enable-row-transition .el-table__body td, .el-table .cell {
  background-color: transparent;
  border: none;
}

.tableBox /deep/ .el-table td, thead {
  color: #1eb0fc;
  font-size: 16px;
}

.tableBox /deep/ .el-table th, thead {
  color: #1eb0fc;
  font-size: 16px;
  padding: 5px 0;
}

.el-table--border::after, .el-table--group::after, .el-table::before {
  display: none;
}

</style>